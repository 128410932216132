import React from 'react';
import { Link } from 'gatsby';
import { useGlobalSettings } from './global-settings';

const BannerSkeleton = ({ type, children }) => <div className={`p-4 ${type}`}>{children}</div>;

const PromoStrip = () => (
  <div className="flex flex-col flex-1 md:justify-center text-center max-w-6xl mx-auto w-full text-lg text-blue-900 font-bold tracking-wide ">
    <p>
      Avoid social isolation and{' '}
      <Link className="text-blue-700" to="/blog/category/coronadiary">
        join our <span className="uppercase">#coronadiary</span> movement
      </Link>{' '}
    </p>
  </div>
);

const TrialBanner = () => (
  <div className="flex flex-col flex-1 md:justify-center text-center max-w-6xl mx-auto w-full text-base text-blue-900 leading-loose">
    <p>
      <span role="img" aria-labelledby="warning sign">
        ⚠️
      </span>{' '}
      You&apos;re signed up for a 30-days free trial.{' '}
      <Link className="text-blue-500" to="/support">
        Contact support
      </Link>{' '}
      to upgrade{' '}
      <span role="img" aria-labelledby="warning sign">
        ⚠️
      </span>
    </p>
  </div>
);

const renderBannerContent = type => {
  switch (type) {
    case 'trial':
      return (
        <BannerSkeleton type="warning">
          <TrialBanner />
        </BannerSkeleton>
      );
    case 'promo':
      return (
        <BannerSkeleton>
          <PromoStrip />
        </BannerSkeleton>
      );
    default:
      return null;
  }
};

const TopBanner = () => {
  const globalSettings = useGlobalSettings();
  const { settings } = globalSettings;

  return renderBannerContent(settings.bannerType);
};

export default TopBanner;
