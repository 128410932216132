import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Header from '../global/header';
import Footer from '../global/footer';
import { ProvideGlobalSettings } from '../global-settings';
import TopBanner from '../top-banner';

function Layout({ showNavigation, children }) {
  return (
    <ProvideGlobalSettings>
      <div className="flex flex-col font-sans min-h-screen relative">
        <Helmet meta={[{ charset: 'UTF-8' }]} />
        <TopBanner />
        <div>
          <Header showNavigation={showNavigation} />
        </div>

        <div className="flex flex-col flex-1 md:justify-center max-w-6xl mx-auto px-4 py-8 md:p-8 w-full">
          {children}
        </div>

        <Footer />

        <script
          id="mcjs"
          data-cookieconsent="statistics"
          src="https://chimpstatic.com/mcjs-connected/js/users/bb64d4f75cf09beb49c9a2918/464c8aeb9dbf315d4d76ff8a8.js"
        />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="eb075372-4761-417f-91bb-cc65d12b6e5c"
          type="text/javascript"
          async
        />
      </div>
    </ProvideGlobalSettings>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showNavigation: PropTypes.bool,
};

Layout.defaultProps = {
  showNavigation: true,
};

export default Layout;
