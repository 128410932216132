import React from 'react';

// import { graphql } from 'gatsby';
import Layout from '../components/layouts/layout';
import Plan from '../components/pricing/plan';
import SEO from '../components/seo';

/*export const query = graphql`
  query GetContactPageSettings($locale: String!) {
    pageSettings: contentfulContactPageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }

      keywords {
        keywords
      }

      mainHeading
      textSectionContent {
        textSectionContent
      }
    }
  }
`;*/

function PricingPage({ data }) {
  return (
    <Layout>
      <SEO title="WeRemote.EU Pricing" />
      <h1 className="font-extrabold md:text-5xl lg:text-6xl p-3">First, choose one.</h1>
      <p className="text-xl md:text-2xl p-3">
        Looking to hire, or to get hired? 
        It doesn't matter, we got you covered. 
        Pick the plan that's right for you and get an account in no time.
      </p>
      <div className="flex flex-col justify-between w-full mb-20 leading-normal">
        <Plan type="paid" />
        <Plan type="free" />
      </div>
    </Layout>
  );
}

export default PricingPage;
