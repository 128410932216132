import React, { useState } from 'react';

enum Prices {
  Yearly = '367.90',
  Monthly = '399.99',
}

const CandidateFeatures = () => (
  <div className="md:flex items-center justify-center md:w-3/4 md:bg-white">
    <div className="py-6 px-8 md:py-0">
      <h2 className="text-gray-700 text-3xl font-bold md:text-black">Candidate</h2>
      <p className="mt-2 text-gray-600 md:text-gray-600">
        No hidden fees, no billing, no credit card needed.
      </p>
    </div>
    <ul className="flex flex-col md:flex-row flex-wrap sm:px-8 text-base md:text-sm mb-2 list-custom">
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">Easily find jobs</li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">Submit job requests</li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">
        Filters for intersting jobs
      </li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">
        Apply in-app - No more filling endless forms
      </li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">
        Control what you share with employers
      </li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">LinkedIn signup</li>
    </ul>
  </div>
);

const CompanyFeatures = () => (
  <div className="md:flex items-center justify-center md:w-3/4 md:bg-white">
    <div className="py-6 px-8 md:py-0">
      <h2 className="text-gray-700 text-3xl font-bold md:text-black">Employer</h2>
      <p className="mt-2 text-gray-600 md:text-gray-600">
        One plan, billed recurrently, monthly or yearly.
      </p>
    </div>
    <ul className="flex flex-col md:flex-row flex-wrap sm:px-8 text-base md:text-sm mb-2 list-custom">
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">Unlimited active jobs</li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">
        Unlimited job applications / candidates
      </li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">
        Import jobs from Workable.com
      </li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">
        Import jobs from any CSV/XLSX file
      </li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">
        Job posting statistics
      </li>
      <li className="w-2/2 sm:w-2/2 md:w-1/2 lg:w-2/2 xl:w-1/2 mb-2 h-12">
        High discoverability through our site-wide search
      </li>
    </ul>
  </div>
);

const Actions = ({ onClick }) => (
  <>
    <label onFocus={onClick} htmlFor="monthly">
      <input type="radio" name="billingCycle" value="monthly" id="monthly" />
      <span className="text-base ml-1 mr-4">Monthly</span>
    </label>
    <label onFocus={onClick} htmlFor="yearly">
      <input type="radio" checked name="billingCycle" value="yearly" id="yearly" />
      <span className="text-base ml-1 mr-4">Yearly</span>
    </label>
  </>
);

const SignUp = ({ to }) => (
  <div className="flex flex-col items-center justify-center w-full">
      <a href={to} className="mx-auto lg:mx-0 my-2 hover:underline cta shadow-lg">
        Sign up for free
      </a>
    <span className="text-sm text-gray-700 underline">No credit card required</span>
  </div>
);

const Plan = ({ type }) => {
  const types = {
    free: `${process.env.GATSBY_APP_ROOT}/signup`,
    paid: `${process.env.GATSBY_APP_ROOT}/signup/employer`,
  };

  const [price, setPrice] = useState(Prices.Yearly);
  return (
    <div className="flex flex-col max-w-full md:h-64 bg-white rounded-lg shadow-lg overflow-hidden md:flex-row my-10">
      {type === 'paid' ? <CompanyFeatures /> : <CandidateFeatures />}
      <div className="flex flex-col items-center justify-center pb-6 md:py-0 md:w-1/4 bg-gray-200">
        <div className="md:flex flex-col items-center justify-center text-center">
          {type === 'paid' ? (
            <div>
              <h2 className="text-5xl md:text-4xl font-bold text-black">
                &euro; {price} <span className="text-base text-gray-700 italic">/ month</span>
              </h2>
              <Actions
                onClick={e => {
                  setPrice(
                    Prices[`${e.target.value[0].toUpperCase()}${e.target.value.substring(1)}`]
                  );
                }}
              />
            </div>
          ) : (
            <h2 className="text-5xl md:text-4xl font-bold text-black">Free, forever</h2>
          )}
        </div>

        <SignUp to={types[type]} />
      </div>
    </div>
  );
};

export default Plan;
