import React, { useState, useContext, createContext } from 'react';

const globalSettingsContext = createContext();

export const BannerTypes = {
  Promo: 'promo',
  Trial: 'trial',
  None: 'none',
};

export const useGlobalSettings = () => useContext(globalSettingsContext);

const useProvideGlobalSettings = () => {
  const [settings, setSettings] = useState({
    bannerType: BannerTypes.None,
  });

  const setBanner = bannerType =>
    setSettings({
      ...settings,
      bannerType,
    });

  return {
    settings,
    setBanner,
  };
};

export const ProvideGlobalSettings = ({ children }) => {
  const settings = useProvideGlobalSettings();
  return (
    <globalSettingsContext.Provider value={settings}>
      {children instanceof Function ? children() : children}
    </globalSettingsContext.Provider>
  );
};

export const ConsumeGlobalSettings = ({ children }) => (
  <globalSettingsContext.Consumer>{children}</globalSettingsContext.Consumer>
);
